<template>
<div>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- Cabecera -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <!-- Boton Agregar nueva Factura de Compra -->
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="openWindow(null)"
            title="Crear Nueva Factura de Compra"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <!-- Titulo Filtros -->
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtros
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <!-- Filtros -->
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar">
              <!-- Empresa, Proveedor, Fecha Desde y Fecha Hasta -->
              <v-row>
                <v-col cols="12" sm="3" :md="big ? 3 : 2" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="selectedEmpresa"
                    item-text="nombre_corto"
                    :items="empresas"
                    hide-details
                    clearable
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-1">
                  Proveedor
                  <v-autocomplete
                    v-model="selectedProveedor"
                    item-text="proveedor_nombre"
                    :items="proveedores"
                    hide-details
                    clearable
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" :sm="big ? 6 : 3" :md="big ? 6 : 3" class="py-1">
                  Fecha
                  <FechaPickerRangoVue
                    v-model="fechaRango"
                    :limpiar.sync="limpiarFecha"
                    hideDetails
                    @changeSize="changeSize"
                  />
                </v-col>
                <v-col cols="12" sm="3" md="2" class="py-1">
                  Id Fact Compra
                  <v-text-field
                    v-model="idFactura"
                    outlined
                    dense
                    type="number"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="2" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="selectedEstado"
                    item-text="estado_nombre"
                    :items="estados"
                    hide-details
                    clearable
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" :md="big ? 2 : 3" class="py-1">
                  Tipo Fac
                  <v-text-field
                    v-model="tipoFactura"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-1">
                  Emision
                  <v-text-field
                    v-model="emision"
                    outlined
                    dense
                    type="number"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-1">
                  Numero
                  <v-text-field
                    v-model="numero"
                    outlined
                    dense
                    type="number"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-1">
                  Referencia
                  <v-text-field
                    v-model="referencia"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-1">
                  Usuario
                  <v-text-field
                    v-model="usuario"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-1">
                  Sucursales
                  <v-autocomplete
                    v-model="selectedSucursal"
                    item-text="nombre"
                    :items="sucursales"
                    hide-details
                    clearable
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="pt-1">
                  Estado Control
                  <v-autocomplete
                    v-model="selectedEstadoControl"
                    item-text="estado_control_nombre"
                    :items="estadosControl"
                    hide-details
                    clearable
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <!-- Botones -->
                <v-col cols="12" :sm="big? 9 : 12" :md="big? 12 : 3" class="py-1 px-0 d-flex justify-end">
                  <BtnFiltroVue
                    :loading="load"
                    @clear="limpiar()"
                    class="pt-0"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Listado -->
      <v-data-table
        v-model="selected"
        class="cebra elevation-2 mt-2"
        :headers="headers"
        :items="facturasCompra"
        :loading="load"
        dense
        :search="search"
        @contextmenu:row="clickDerecho"
        :single-select="false"
        item-key="factura_id"
        :item-class="miBand ? ponerSinBlod : ponerBold"
      >
        <!-- Buscador en el datatable -->
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="9" md="10" class="d-flex justify-start align-center">
              <DownloadDataTableVue
                name="Factura de Compra"
                :data="facturasCompra"
                :headers="headers_excel"
              />
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTableVue
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
        <template
          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <!-- Acomodo la emision y numero -->
        <template v-slot:[`item.comprobante`]="{ item }">
          <v-chip :color="item.comprobante.toString().indexOf('NC') != -1 ? 'error' : ''" small>
            {{ item.comprobante }}
          </v-chip>
        </template>
        <!-- Acciones y modal para confirmar -->
        <template v-slot:[`item.acciones`]="{ item }">
          <v-btn icon small @click="openWindow(item)"><v-icon small color="primary" title="Ver">fas fa-eye</v-icon></v-btn>
          <v-btn icon small @click="subirArchivo(item)"><v-icon small :color="parseInt(item.con_archivo) > 0 ? 'success' : 'error'" title="Subir Archivos">fas fa-cloud-upload-alt</v-icon></v-btn>
        </template>
        <!-- Msj que se mostrara si no existen resultados -->
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
        <template v-slot:[`item.estado_control_nombre`]="{item}">
          <strong>{{item.estado_control_nombre}}</strong>
        </template>
        <template v-slot:[`item.factura_id`]="{item}">
          <strong>{{item.factura_id}}</strong>
        </template>
      </v-data-table>
      <!-- Modal Subir Archivos -->
      <ModalSubirArchivosVue
        :ruta="ruta_carpeta"
        :activo="dialogSubirArchivo"
        :facturaId="facturaSeleccionada"
        @cerrarModalSA="closeModalSubirArchivo()"
      />
      <!-- Modal Subir Archivos -->
       <!-- <ModalUploadImgGralVue 
        :datos="dialogSubirImgs"
        @setearModalUpImg="setModalUpImg"
      /> -->
    </v-col>
    <!-- Menu del Click Derecho -->
    <v-menu
      v-model="menu.activo"
      :position-x="menu.x"
      :position-y="menu.y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item
          @click="anularFacturaCompra()"
        >
          <v-list-item-title>
            <v-icon color="error" small left>fas fa-trash</v-icon>
            Anular Compra
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="preparaModalRecepciones()"
        >
          <v-list-item-title>
            <v-icon color="success" small left>fas fa-compress-arrows-alt</v-icon>
            Asociar Recepciones
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="preparaModalOrdenes()"
        >
          <v-list-item-title>
            <v-icon color="info" small left>fas fa-compress-arrows-alt</v-icon>
            Asociar Ordenes de compra
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- Modal Ver Recepciones -->
    <ModalVerRecepcionesVue 
      :datos="dialogVerRecepciones"
      @setearModalVerRecep="setModalVerRecep"
    />
    <!-- Modal Ver Ordenes de Compra -->
    <ModalVerOrdCompraVue
      :datos="dialogVerOrdCompra"
      @setearModalVerOrdComp="setModalVerOrdComp"
    />
  </v-row>
</div>
</template>

<script>
import moment from 'moment';
import BtnConfirmarVue from '../../components/util/BtnConfirmar.vue';
import BtnFiltroVue from '../../components/util/BtnFiltro.vue';
import FechaPickerVue from '../../components/util/FechaPicker.vue';
import FechaPickerRangoVue from '../../components/util/FechaPickerRango.vue';
import SearchDataTableVue from '../../components/util/SearchDataTable.vue';
import { format_money, roundNumber, objetoNoVacio } from '../../util/utils';
import ModalSubirArchivosVue from '../../components/generales/facturasCompra/ModalSubirArchivos.vue';
import ModalUploadImgGralVue from '../../components/generales/ModalUploadImgGral.vue';
import DownloadDataTableVue from '../../components/util/DownloadDataTable.vue';
import ModalVerOrdCompraVue from '../../components/ordenes-compra/ModalVerOrdCompra.vue';
import ModalVerRecepcionesVue from '../../components/ordenes-compra/ModalVerRecepciones.vue';

export default {
  name: 'IndexFacturasCompraAud',
  data(){
    return{
      panel:0,
      format_money: format_money,
      roundNumber: roundNumber,
      moment: moment,
      big: false,
      //init
      empresas: [],
      sucursales: [],
      estados: [],
      estadosControl: [],
      proveedores: [],
      dialogSubirImgs: {
        activo: false,
        icono: 'fas fa-eye',
        titulo_modal: 'Archivos para La Orden de Pago ',
        tipos: ['.jpg', '.png', '.webp', '.pdf'], // formatos admitidos
        multiple: true,
        max_size: 1, // tamaño del archivo en megas
        ruta: '', // ruta del archivo
        raiz: 0, // indica si vamos a trabajar con la raiz del 10.40 o la de jarvis 2
        tabla: 6, // indica la tabla donde va el log
        id: 0, // generalmente indica un numero identificador para la tabla del log, por ejemplo para facturas de compra seria el id de la factura de compra en la tabla LOG_FACTURAS_COMPRAS en el campo FACTURA_COMPRA_ID
        permiso_borrar: 0 // permiso para borrar archivos
      },
      tienePermiso: 0,
      //filtro
      selectedEmpresa: {},
      selectedProveedor: {},
      fechaRango: [ null, null ],
      limpiarFecha: false,
      idFactura: '',
      selectedEstado: {},
      tipoFactura: '',
      emision: '',
      numero: '',
      referencia: '',
      usuario: '',
      selectedSucursal: {},
      selectedEstadoControl: {},
      fechaDesde: null,
      fechaHasta: '',
      ordenPago: '',
      //
      //Data-table
      load: false,
      search: '',
      facturasCompra: [],
      headers: [
        { text: 'Id', align: 'center', value: 'factura_id' },
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Proveedor', align: 'left', value: 'proveedor_nombre' },
        { text: 'Sucursal', align: 'left', value: 'sucursal_nombre' },
        { text: 'Estado', align: 'center', value: 'estado_nombre' },
        { text: 'Estado control', value: 'estado_control_nombre' },
        { text: 'Emis-Nro', align: 'left', value: 'comprobante' },
        { text: 'Total', align: 'right', value: 'total_real', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      headers_excel:{
        'Id': 'factura_id',
        'Fecha': 'fecha',
        'Proveedor': 'proveedor_nombre',
        'Sucursal': 'sucursal_nombre',
        'Estado': 'estado_nombre',
        'Estado control': 'estado_control_nombre',
        'Emis-Nro': 'comprobante',
        'Total': 'total_real',
      },
      ruta_carpeta: '/facturas_compras/',
      dialogSubirArchivo: false,
      facturaSeleccionada: '',
      actualFacturaCompra: {},
      menu: {
        activo: false,
        x: 0,
        y: 0
      },
      dialogVerOrdCompra: {
        activo: false,
        orden_id: 0,
        factura_id: 0,
        auditoria: 1, //se marca en 1 cuando es auditoria, 0 si es administración
        proveedor_codigo: 0, //se usa para buscar el proveedor
      },
      dialogVerRecepciones: {
        activo: false,
        orden_id: 0,
        factura_id: 0,
        auditoria: 1, //se marca en 1 cuando es auditoria, 0 si es administración
        proveedor_codigo: 0, //se usa para buscar el proveedor
      },
      dialogPagar: {
        activo: false,
        proveedor_codigo: '',
        proveedor_nombre: '',
        facturas: []
      },
      selected: [],
      disabledCount: 0,
      dialogConfAsociar: false,
      miBand: false,
      pantallaChica: this.$vuetify.breakpoint.xs,
    }
  },
  created(){
    this.init();
  },
  methods:{
    async init(){
      this.$store.state.loading = true
      this.empresas = JSON.parse(localStorage.getItem('empresas'))
      this.sucursales = JSON.parse(localStorage.getItem('sucursales'))
      // obtener los estados
      this.$store.dispatch('bejerman/get_fc_data')
        .then(response => {
          this.$store.state.loading = false
          if (response.resultado == 1){
            this.estados = response.estados
            this.proveedores = response.proveedores
            this.estadosControl = response.estados_control
            this.dialogSubirImgs.permiso_borrar = response.permiso
            this.tienePermiso = response.permiso_pagar
          }else{
            this.$store.dispatch('show_snackbar', {
              text: response.msj,
              color: 'error',
            })
            return
          }
        })
        .catch(error => {
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo obtener los estados de facturas: ' + error.message,
            color: 'error',
          })
          return
        })
    },
    limpiar(){
      this.facturasCompra = [];
      this.selectedEstado = {}
      this.selectedEmpresa = {}
      this.selectedSucursal = {}
      this.selectedProveedor = {}
      this.idFactura = ''
      this.tipoFactura = ''
      this.emision = ''
      this.numero = ''
      this.referencia = ''
      this.usuario = ''
      this.limpiarFecha = true
      this.fechaDesde = null
      this.fechaHasta = ''
      this.ordenPago = ''
      this.miBand = true;
      this.actualFacturaCompra = {};
      this.search = '';
    },
    camposVacios(){
      if (this.idFactura.length == 0 && Object.keys(this.selectedEstado).length == 0
       && this.tipoFactura.length == 0 && this.emision.length == 0 && this.numero.length == 0
       && this.referencia.length == 0 && this.usuario.length == 0 && Object.keys(this.selectedSucursal).length == 0 
       && Object.keys(this.selectedProveedor).length == 0 && Object.keys(this.selectedEmpresa).length == 0 && !this.fechaDesde){
        return true
      }else{
        return false
      }
    },
    async buscar(){
      if (/*this.camposVacios()*/!this.fechaRango[0] ){
        this.$store.dispatch('show_snackbar', {
          text: 'Por favor, ingrese alguna fecha para empezar la búsqueda.',
          color: 'warning',
        })
        return
      }
      this.miBand = true; this.actualFacturaCompra = {};
      this.load = true;
      this.selected = [];
      this.facturasCompra = [];
      this.search = '';
      // obtengo los datos de estado, estado de control, empresa, proveedor y sucursal
      let estadoF = 0
      let estadoC = 0
      let empresaF = 0
      let proveedorF = 0
      let sucurusalF = 0
      if (Object.keys(this.selectedEstado).length > 0){
        estadoF = this.selectedEstado.estado_id
      }
      if (Object.keys(this.selectedEstadoControl).length > 0){
        estadoC = this.selectedEstadoControl.estado_control_id
      }
      if (Object.keys(this.selectedEmpresa).length > 0){
        empresaF = this.selectedEmpresa.id
      }
      if (Object.keys(this.selectedProveedor).length > 0){
        proveedorF = this.selectedProveedor.proveedor_codigo
      }
      if (Object.keys(this.selectedSucursal).length > 0){
        sucurusalF = this.selectedSucursal.id
      }
      // fecha hasta
      let fechaHastaBus = ''
      if (this.fechaRango[1] && null || this.fechaRango[1] != undefined && this.fechaRango[1].length > 0){
        fechaHastaBus = moment(this.fechaRango[1]).format('DD/MM/YYYY')
      }
      let fDesde = ''
      if (this.fechaDesde != null && this.fechaDesde != undefined && this.fechaDesde.toString().length > 0) fDesde = this.fechaDesde //moment(this.fechaDesde, 'YYYY-MM-DD').format('DD/MM/YYYY')
      let fHasta = ''
      if (this.fechaHasta != null && this.fechaHasta != undefined && this.fechaHasta.toString().length > 0) fHasta = this.fechaHasta //moment(this.fechaHasta, 'YYYY-MM-DD').format('DD/MM/YYYY')
      // orden de pago
      let oPago = 0
      if (this.ordenPago != null && this.ordenPago != undefined && this.ordenPago != 0 && this.ordenPago.toString().length > 0) oPago = this.ordenPago
      this.$store.dispatch('bejerman/get_facturas_compra_admin', {
        idFactura: this.idFactura,
        estado: estadoF,
        estadoControl: estadoC,
        fechaDesde: moment(this.fechaRango[0]).format('DD/MM/YYYY'),
        fechaHasta: fechaHastaBus,
        tipoFactura: this.tipoFactura.toUpperCase(),
        emision: this.emision,
        numero: this.numero,
        referencia: this.referencia,
        usuario: this.usuario,
        empresa: empresaF,
        proveedor: proveedorF,
        sucursal: sucurusalF,
        fDesde: fDesde,
        fHasta: fHasta,
        ordenPago: oPago
      })
        .then(response => {
          this.load = false
          if (response.resultado == 1){
            this.facturasCompra = response.facturasComp
            for (let id in this.facturasCompra){
              this.facturasCompra[id].fecha = moment(this.facturasCompra[id].fecha).format('DD/MM/YYYY')
            }
          }else{
            this.$store.dispatch('show_snackbar', {
              text: response.msj,
              color: 'error',
            })
            return
          }
        })
        .catch(error => {
          this.load = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo obtener las Facturas de Compra: ' + error.message,
            color: 'error',
          })
          return
        })
        this.miBand = false;
    },
    anularFacturaCompra(){
      this.$swal.fire({
        icon: 'warning',
        title: `¿Esta seguro de Anular la Factura ${this.actualFacturaCompra.factura_id}?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then((res) => {
        if(res.isConfirmed){  
          if(this.actualFacturaCompra.if_anula == 1){
            this.$store.state.loading = true
            this.$store.dispatch('facturasCompra/anular_factura_compra', {ifFactura: this.actualFacturaCompra.factura_id})
            .then(response => {
              this.$store.state.loading = false
              if (response.resultado == 1){
                this.$store.dispatch('show_snackbar', {
                  text: 'Se anuló con éxito la factura ' + this.actualFacturaCompra.factura_id + '.',
                  color: 'success',
                })
                let pos = this.facturasCompra.indexOf(this.actualFacturaCompra)
                this.facturasCompra[pos].estado_codigo = 3
                this.facturasCompra[pos].estado_nombre = 'ANULADO'
                this.facturasCompra[pos].if_anula = 0
                this.facturasCompra[pos].if_modifica = 0
              }else{
                this.$store.dispatch('show_snackbar', {
                  text: response.msj,
                  color: 'error',
                })
                return
              }
            })
            .catch(error => {
              this.$store.state.loading = false
              this.$store.dispatch('show_snackbar', {
                text: 'No se pudo anular la Factura: ' + error.message,
                color: 'error',
              })
              return
            })
          }else{
            return this.$store.dispatch('show_snackbar',{
              text: `No se puede Anular la Factura seleccionada.`,
              color: 'error'
            })
          }
        }
      })
    },
    openWindow(item){ // accion del ojo azul y el boton verde del +
      let path = '/aud-nueva-factura-compra'
      if(item){
        path = `/aud-factura-compra/${item.factura_id}`
      }
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    subirArchivo(item){
      this.facturaSeleccionada = ''
      // indico la ruta donde se van a guardar los archivos
      this.ruta_carpeta = '/facturas_compras/' + item.factura_id
      this.facturaSeleccionada = item.factura_id.toString()
      // abro el modal
      this.dialogSubirArchivo = true
    },
    closeModalSubirArchivo(){
      this.dialogSubirArchivo = false;
    },
    clickDerecho(event, item){
      event.preventDefault()
      this.actualFacturaCompra = {}
      this.menu.activo = false
      this.menu.x = event.clientX
      this.menu.y = event.clientY
      this.$nextTick(() => {
        this.menu.activo = true
        if (item.item.estado_control_id != 4){ 
          this.actualFacturaCompra = item.item
        }
      })
    },
    ponerBold(item){
      return item.factura_id === this.actualFacturaCompra.factura_id ? ['font-weight-bold'] : '';
    },
    ponerSinBlod(){
      return '';
    },
    setModalUpImg(value){
      this.dialogSubirImgs.activo = value
      if (this.dialogSubirImgs.activo == false){
        this.dialogSubirImgs.icono = 'fas fa-eye'
        this.dialogSubirImgs.titulo_modal = 'Archivos para La Orden de Pago '
      }
      // '/facturas_compras/' + this.factura_id
    },
    setModalVerOrdComp(value){
      this.dialogVerOrdCompra.activo = value
      if (this.dialogVerOrdCompra.activo == false){
        this.actualFacturaCompra = {}
        this.dialogVerOrdCompra.factura_id = 0
        this.dialogVerOrdCompra.proveedor_codigo = 0
      }
    },
    setModalVerRecep(value){
      this.dialogVerRecepciones.activo = value
      if (this.dialogVerRecepciones.activo == false){
        this.actualFacturaCompra = {}
        this.dialogVerRecepciones.factura_id = 0
      }
    },
    preparaModalRecepciones(){
      if(objetoNoVacio(this.actualFacturaCompra)){
        this.dialogVerRecepciones.factura_id = this.actualFacturaCompra.factura_id; 
        this.dialogVerRecepciones.proveedor_codigo = this.actualFacturaCompra.proveedor_codigo
        this.dialogVerRecepciones.activo = true;
      }else{
        this.$store.dispatch('show_snackbar', {
          text: 'El estado de control actual no permite ver esta información',
          color: 'info',
        })
        return
      }
    },
    preparaModalOrdenes(){
      if(objetoNoVacio(this.actualFacturaCompra)){
        this.dialogVerOrdCompra.activo = true; 
        this.dialogVerOrdCompra.factura_id = this.actualFacturaCompra.factura_id;
        this.dialogVerOrdCompra.proveedor_codigo = this.actualFacturaCompra.proveedor_codigo;
      }else{
        this.$store.dispatch('show_snackbar', {
          text: 'El estado de control actual no permite ver esta información',
          color: 'info',
        })
        return
      }
    },
    changeSize (custom) {
      this.big = custom
    }
  },
  computed:{

  },
  components:{
    BtnFiltroVue, BtnConfirmarVue, SearchDataTableVue, FechaPickerVue, FechaPickerRangoVue, ModalSubirArchivosVue,
    ModalUploadImgGralVue, DownloadDataTableVue, ModalVerOrdCompraVue, ModalVerRecepcionesVue
  },
  watch:{
    selectedEmpresa: function(){
      if (this.selectedEmpresa == null || this.selectedEmpresa == undefined){
        this.selectedEmpresa = {}
        this.selectedProveedor = {}
        this.proveedores = []
      }
    },
    selectedEstado: function(){
      if (this.selectedEstado == null || this.selectedEstado == undefined){
        this.selectedEstado = {}
      }
    },
    selectedProveedor: function(){
      if (this.selectedProveedor == null || this.selectedProveedor == undefined){
        this.selectedProveedor = {}
      }
    },
    selectedSucursal: function(){
      if (this.selectedSucursal == null || this.selectedSucursal == undefined){
        this.selectedSucursal = {}
      }
    },
    selectedEstadoControl: function(){
      if (this.selectedEstadoControl == null || this.selectedEstadoControl == undefined) this.selectedEstadoControl = {}
    },
    facturasCompra: function(){
      if (this.facturasCompra.length > 0){
        const self = this;
        this.facturasCompra.map(item => {
          if (item.estado_control_id == 4) self.disabledCount += 1
        })
      }
    },
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  }
}
</script>

<style>

</style>